import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Button,
  InputWrapper,
  InputLabel,
  InputGroup,
  InputError,
  InputMask,
  InputNumeric,
  InputAddon,
  Form,
  Lede,
} from 'fiducius-ui';

import {
  safeAccess,
  debounce,
  formatAsMoney,
  convertIsoToSlash,
  mergeRequestStatuses,
} from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import StandardConfirmation from '../components/standard-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
import StepDisplay from '../components/step-display';
import ComponentStepDisplay from '../components/component-step-display';
import { MessageTile } from '../../../messages';
import { MessageDiv } from '../../../messages';

import {
  idrCopyCompleteToForm,
  idrLoadComplete,
  idrHandleFormChange,
} from '../../../workflows/idr/redux/operations';

class Idr extends React.Component {
  static propTypes = {
    data: t.object.isRequired,
    form: t.object.isRequired,
    repayTypeName: t.string.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    onLoad: t.func.isRequired,
    action: t.func.isRequired,
    isMarried: t.bool.isRequired,
    errors: t.object,
    todos: t.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      showStep1: false,
      showStep2: false,
      showStep3: false,
      customStep3Show: false,
      goToStep3: false,
    };
  }

  componentDidMount() {
    this.props.onLoad();
    const pathName = window.location.pathname;
    if (pathName.includes('/step')) {
      this.setState({
        ...this.state,
        showStep1: pathName.includes('/step-1'),
        showStep2: pathName.includes('/step-2'),
        showStep3: pathName.includes('/step-3'),
      });
    }
  }

  componentDidUpdate() {}

  handleChange = debounce((formState, id, attribute) => {
    this.props.updateForm({ ...this.props.form, ...formState }, [attribute]);
  }, 250);

  goToStep3 = () => {
    this.setState({ customStep3Show: true }, () => {
      this.scrollToStep('customStep3');
    });
  };

  handleStep1Click = () => {
    this.handleSectionClick('step1');
  };
  handleStep2Click = () => {
    this.handleSectionClick('step2');
  };
  handleStep3Click = () => {
    this.handleSectionClick('step3');
  };

  handleSectionClick = (sectionName) => {
    if (sectionName === 'step1') {
      this.setState(
        {
          showStep1: true,
          showStep2: false,
          showStep3: false,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    } else if (sectionName === 'step2') {
      this.setState(
        {
          showStep1: false,
          showStep2: true,
          showStep3: false,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    } else if (sectionName === 'step3') {
      this.setState(
        {
          showStep1: false,
          showStep2: false,
          showStep3: true,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    }
  };

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  getStep1Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'IDR');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 111);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          } else {
            const nextToDo = todos.find((a) => a.id === 112 || a.id === 113);
            if (nextToDo !== undefined && nextToDo !== null) {
              retVal = 'success';
            }
          }
        }
      }
    }

    return retVal;
  };

  getStep2Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'IDR');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 112);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          } else {
            const nextToDo = todos.find((a) => a.id === 113);
            if (nextToDo !== undefined && nextToDo !== null) {
              retVal = 'success';
            }
          }
        }
      }
    }

    return retVal;
  };

  getStep3Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'IDR');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 113);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          }
        }
      }
    }

    return retVal;
  };

  getStep3RestrictionDate = () => {
    let retVal = undefined;
    const { permissions } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'IDR');
    if (empCertStep.stepDateRestriction !== undefined) {
      let stepRestriction = new Date(empCertStep.stepDateRestriction);
      retVal =
        (stepRestriction.getMonth() + 1).toString() +
        '/' +
        stepRestriction.getDate() +
        '/' +
        stepRestriction.getFullYear();
    }
    return retVal;
  };

  getServicerNames = () => {
    const { form } = this.props;
    return (
      <>
        <ul>
          {form.servicerNames.map((item, i) => (
            <>
              <li key={i}>{item}</li>
            </>
          ))}
        </ul>
      </>
    );
  };

  render() {
    const { errors, isMarried, repayTypeName, form, permissions, data } = this.props;
    const restrictionDate = this.getStep3RestrictionDate();

    let isMarriedText = <></>;
    if (isMarried) {
      isMarriedText = (
        <>
          <li>
            Please verify your spouse's Social Security Number is present on Question 9 (page 2).
            <ul>
              <li>This is only necessary if your spouse has a federal student loan balance.</li>
            </ul>
          </li>
        </>
      );
    }

    let repaymentPlanTypeInfo;
    if (repayTypeName !== undefined && repayTypeName !== null && repayTypeName !== '') {
      repaymentPlanTypeInfo = (
        <>
          Your repayment plan type should be <strong>{repayTypeName.toString()}</strong>.
        </>
      );
    } else {
      repaymentPlanTypeInfo = (
        <strong>
          Your repayment plan type isn't set. Please visit the Contact Us button on the left of your
          portal to reach out to us regarding this.
        </strong>
      );
    }

    let repaymentPlanAmountInfo;
    if (
      form.projectedIdr !== undefined &&
      form.projectedIdr !== null &&
      !isNaN(form.projectedIdr)
    ) {
      repaymentPlanAmountInfo = (
        <>
          Your repayment amount should be <strong>{formatAsMoney(form.projectedIdr)}</strong>.
        </>
      );
    } else {
      repaymentPlanAmountInfo = (
        <strong>
          Your repayment amount isn't set. Please visit the Contact Us button on the left of your
          portal to reach out to us regarding this.
        </strong>
      );
    }

    let planChangingInfo;
    let projectedRepaymentPlanType = data.projectedRepaymentType;

    if (projectedRepaymentPlanType === 'REPAY') {
      projectedRepaymentPlanType = 'REPAYE';
    }

    switch (data.planChangingCode) {
      case 1:
        planChangingInfo = (
          <li>
            Note: Due to the changes you reported with your marital and/or tax filing status, you
            will be changing to the {projectedRepaymentPlanType} plan this year to maximum your
            financial wellness.
          </li>
        );
        break;
      default:
        break;
    }

    return (
      <>
        <ForgivenessProgress />
        <DashboardSection>
          <Card brand="primary">
            <CardBody>
              <CardTitle>Repayment Application</CardTitle>
              <CardText>
                <p>
                  Completing this step will submit your application to set up your new Income-Driven
                  Repayment (IDR) for this year.
                </p>
                <p>
                  <MessageDiv messageType={'IDR_Tab'} />
                </p>
                <div className="row col-12">
                  <div className="col-4">
                    <Lede>
                      <Button brand={this.getStep1Brand()} onClick={this.handleStep1Click}>
                        Generate
                      </Button>
                    </Lede>
                  </div>
                  <div className="col-4">
                    <Lede>
                      <Button brand={this.getStep2Brand()} onClick={this.handleStep2Click}>
                        Submit
                      </Button>
                    </Lede>
                  </div>
                  <div className="col-4">
                    <Lede>
                      <Button brand={this.getStep3Brand()} onClick={this.handleStep3Click}>
                        Review
                      </Button>
                    </Lede>
                  </div>
                </div>
              </CardText>
            </CardBody>
          </Card>
        </DashboardSection>
        {this.state.showStep1 && (
          <div id="step1Section">
            <DashboardSection title="Generate your Repayment Application">
              <div id="messageSection">
                <MessageTile messageType={'IDR_Generate'} />
              </div>
              <Card>
                <CardBody disableChildren={data.planChangingCode === 2}>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 - Sign Repayment Application'}>
                      <ul>
                        <li>
                          Please click the button below to generate your Repayment Application.
                        </li>
                        <li>Complete the short questionnaire.</li>
                        <li>Electronically sign the application.</li>
                        <li>
                          After electronically signing your application, you will be taken to your
                          My Documents page where you can download the file.
                        </li>
                        {planChangingInfo}
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  <StandardConfirmation stepId={111} />
                </CardBody>
                {data.planChangingCode === 2 && (
                  <>
                    <CardBody overlayCard>
                      <>
                        <CardTitle overlayCard>
                          Income-Driven Repayment (IDR) Application Hold
                        </CardTitle>
                        <CardText overlayCard>
                          <p>
                            Recent federal modifications have created several changes to the Public
                            Service Loan Forgiveness (PSLF) program that prevent the proper
                            generation and submission of the IDR application for your situation at
                            this time.
                          </p>
                          <p>
                            We are monitoring the modifications and will notify you as soon as the
                            proper application can be generated.
                          </p>
                        </CardText>
                      </>
                    </CardBody>
                  </>
                )}
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep2 && (
          <div id="step2Section">
            <DashboardSection title="Submit your Repayment Application">
              <div id="messageSection">
                <MessageTile messageType={'IDR_Submit'} />
              </div>
              <div className="row">
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <AsyncLoader request={this.props.request} loading={<></>}>
                        <StepDisplay title={'Step 1 – Verify Repayment Application Information'}>
                          <ul>
                            <li>
                              Please verify your Social Security Number is present on the first six
                              pages of your application prior to submitting it.
                            </li>{' '}
                            {isMarriedText}
                            {planChangingInfo}
                          </ul>
                        </StepDisplay>
                        {/*{data.incomeDocToInclude != null && (
                          <Alert brand="warning">{data.incomeDocToInclude}</Alert>
                        )}*/}
                      </AsyncLoader>
                      <StandardConfirmation stepId={112} />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep3 && (
          <div id="step3Section">
            <DashboardSection title="Check on your Repayment Application">
              <div id="messageSection">
                <MessageTile messageType={'IDR_Review'} />
              </div>
              <Card overlayCard={restrictionDate !== undefined}>
                <CardBody overlayCard hideOverlay={restrictionDate === undefined}>
                  {restrictionDate !== undefined && (
                    <>
                      <CardTitle overlayCard>
                        Wait for income-driven repayment to finalize
                      </CardTitle>
                      <CardText overlayCard>
                        <p>
                          Based on the average processing time for this document, it will be
                          processed by your loan servicer on {restrictionDate} at the earliest*. You
                          will not be able to confirm completion of this step until that date.
                        </p>
                        <p>
                          *Please note: Your document is not guaranteed to be processed by this
                          date. It is important to follow your steps closely.
                        </p>
                      </CardText>
                    </>
                  )}
                </CardBody>
                <CardBody disableChildren={restrictionDate !== undefined}>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Access loan servicer account'}>
                      <ul>
                        <li>Login to your account at the following loan servicer(s):</li>
                        {form.servicerNames && this.getServicerNames()}
                      </ul>
                    </StepDisplay>
                    <StepDisplay
                      title={
                        'Step 2 – Check the status of the income-driven repayment (Every seven days)'
                      }
                    >
                      <ul>
                        <li>Check your Loan Details for the "In Repayment" status.</li>
                        <li>
                          <strong>Please note:</strong> It typically takes{' '}
                          <strong>four to eight weeks</strong> for a repayment application to
                          process.
                        </li>
                        <li>
                          Check your Inbox/Documentation at your loan servicer for an approval
                          statement.
                        </li>
                        <li>What are you looking for?</li>
                        <ul>
                          <li>{repaymentPlanAmountInfo}</li>
                        </ul>
                      </ul>
                    </StepDisplay>
                    {/*Contact us at{' '}
<a href="tel:+15136455400">(513) 645-5400</a> option: 2.*/}
                    <div id="customStep3Section">
                      <Form id="idrComplete" handleChange={this.handleChange} defaults={form}>
                        <ComponentStepDisplay
                          title={'Step 3 – Provide repayment details'}
                          show={this.state.customStep3Show}
                        >
                          <CardText>
                            <ul>
                              <li>
                                <InputWrapper error={!!errors.doeRepayDate}>
                                  <InputLabel htmlFor="doeRepayDate">
                                    My repayment plan begins on
                                  </InputLabel>
                                  <InputError>
                                    {safeAccess(errors, 'doeRepayDate.detail')}
                                  </InputError>
                                  <InputGroup affordance="medium">
                                    <InputMask
                                      defaultValue={convertIsoToSlash(form.doeRepayDate)}
                                      mask="##/##/####"
                                      name="doeRepayDate"
                                      placeholder="MM/DD/YYYY"
                                    />
                                  </InputGroup>
                                </InputWrapper>
                              </li>
                              <li>
                                {/* TODO: This needs to be an input field for actual-idr*/}
                                <InputWrapper error={!!errors.actualIdr}>
                                  <InputLabel htmlFor="actualIdr">
                                    My repayment amount is
                                  </InputLabel>
                                  <InputError>{safeAccess(errors, 'actualIdr.detail')}</InputError>
                                  <InputGroup affordance="medium">
                                    <InputAddon>$</InputAddon>
                                    <InputNumeric
                                      name="actualIdr"
                                      step="0.01"
                                      defaultValue={form.actualIdr}
                                    />
                                  </InputGroup>
                                </InputWrapper>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                If your repayment details reflect a higher repayment amount than the
                                one shown on your Fiducius account, please visit the Contact Us
                                button on the left of your portal to reach out to us regarding this.
                                {/*contact us at <a href="tel:+15136455400">(513) 645-5400</a> option: 2.*/}
                              </li>
                            </ul>
                          </CardText>
                        </ComponentStepDisplay>
                      </Form>
                    </div>
                    <StepDisplay title={'Step 4 – Confirm completion'}>
                      <ul>
                        <li>Confirm your correct income-driven repayment has been set up.</li>
                        <ul>
                          <li>
                            The plan type on your repayment details should match the repayment plan
                            type on your Fiducius account.
                          </li>
                          <li>
                            We recommend you save a copy of your repayment details to keep a
                            physical history of your repayments.
                            {permissions.showContribution && (
                              <>
                                Your repayment details will also be needed in the next step for
                                verification of your repayment to establish your employer
                                contribution.
                              </>
                            )}
                          </li>
                        </ul>
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  {form.doeRepayDate !== null &&
                  errors.doeRepayDate === false &&
                  form.actualIdr !== null &&
                  errors.actualIdr === false ? (
                    <>
                      <StandardConfirmation stepId={113} additionalInfoNeeded={false} />
                    </>
                  ) : (
                    <StandardConfirmation
                      stepId={113}
                      additionalInfoNeeded={true}
                      handleChange={this.goToStep3.bind(this)}
                    />
                  )}
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
      </>
    );
  }
}

const mapRepaymentType = (type) => {
  switch (type) {
    case 'PAYE':
      return 'Pay as you earn';
    case 'REPAY':
      return 'Revised pay as you earn';
    case 'IBR':
      return 'Income based repayment';
    case 'ICR':
      return 'Income contingent repayment';
    case 'SAVE':
      return 'Saving on a Valuable Education';
    default:
      return 'Other';
  }
};

const mapStateToProps = (state) => {
  const userId = getUserId(state);
  let data = safeAccess(state, `idr.cache[${userId}]`, {});
  let form = safeAccess(state, 'idr.form', {});
  form.actualRepaymentType = data.projectedRepaymentType;
  form.projectedRepaymentType = data.projectedRepaymentType;

  return {
    data: data,
    form: form,
    request: mergeRequestStatuses([
      state.todos.requests.updateResource,
      state.todos.requests.loadCollection,
      state.idr.requests.updateResource,
      state.selfServiceDocument.requests.updateResource,
      state.selfServiceDocument.requests.loadCollection,
    ]),
    isMarried: safeAccess(state, 'auth.cache.permissions.married', false),
    repayTypeName: mapRepaymentType(data.projectedRepaymentType),
    errors: safeAccess(state, 'idr.errors', {}),
    todos: Object.values(state.todos.cache),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateForm: (state, attributes) => dispatch(idrHandleFormChange(state, attributes)),
  onLoad: async () => {
    await dispatch(idrLoadComplete(ownProps.id));
    dispatch(idrCopyCompleteToForm());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Idr));
