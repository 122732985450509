import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Alert, AsyncLoader, Workflow } from 'fiducius-ui';

// import { advisorInvalidateCache } from '../../../advisor'; => renewal info invalidate
import { getUserId } from '../../../auth';
import { debounce, isEqual, safeAccess } from '../../../utils';

import TabEducation from './tab-education';
import TabEmployment from './tab-employment';
import TabHousehold from './tab-household';
import TabIncome from './tab-income';
import TabMarriage from './tab-marriage';
import TabResults from './tab-results';
import TabReview from './tab-review';
import {
  renewalCopyResourceToForm,
  renewalHandleFormChange,
  renewalLoadResource,
  renewalValidateAttributes,
  renewalEmploymentValidation,
} from '../redux/operations';

class RenewalWorkflow extends React.Component {
  static propTypes = {
    current: t.number,
    data: t.object,
    errors: t.object,
    hasResults: t.bool.isRequired,
    id: t.string,
    loadData: t.func.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { current: props.current || 0 };
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;
    const newData = { ...data, ...formState };

    if (formState.clientMarriageStatusCorrect === 'N') {
      // switch Y/N from initial result if they mark incorrect
      newData.clientCurrentlyMarried = data.initialMarriageStatus === 'married' ? 'N' : 'Y';
    } else if (formState.clientMarriageStatusCorrect === 'Y') {
      // reset in case they change it back to correct
      newData.clientCurrentlyMarried = data.initialMarriageStatus === 'married' ? 'Y' : 'N';
    }

    if (
      id === 'household' &&
      (attribute.includes('dependent') || attribute.includes('householdSize'))
    ) {
      let dependents = [];
      let dependent = {};

      for (let i = 0; i < newData.householdSize; i++) {
        dependent = {
          dependentName: newData['dependentName' + i] || '',
          dependentLastName: newData['dependentLastName' + i] || '',
          dependentRelationship: newData['dependentRelationship' + i] || '',
          dependentBirthdate: newData['dependentBirthdate' + i] || '',
        };

        dependents[i] = dependent;
      }

      newData.dependents = dependents;
    }

    if (id === 'employment' && attribute.includes('employment')) {
      let employers = [];
      let employer = {};
      newData['employersCount'] =
        (isNaN(newData.initialEmployerSize) ? 0 : parseInt(newData.initialEmployerSize)) +
        (isNaN(newData.employmentNewEmployerAmount)
          ? 0
          : parseInt(newData.employmentNewEmployerAmount));

      for (let i = 0; i < newData.employersCount; i++) {
        employer = {
          employmentEmployerName:
            (newData['employmentEmployerName' + i] !== undefined &&
              newData['employmentEmployerName' + i]) ||
            undefined,
          employmentJobTitle:
            (newData['employmentJobTitle' + i] !== undefined &&
              newData['employmentJobTitle' + i]) ||
            '',
          employmentStartDate:
            (newData['employmentStartDate' + i] !== undefined &&
              newData['employmentStartDate' + i]) ||
            '',
          employmentEndDate:
            (newData['employmentEndDate' + i] !== undefined && newData['employmentEndDate' + i]) ||
            '',
          employmentEin:
            (newData['employmentEin' + i] !== undefined && newData['employmentEin' + i]) || '',
          employmentPayPeriods:
            (newData['employmentPayPeriods' + i] !== undefined &&
              newData['employmentPayPeriods' + i]) ||
            '',
          employmentHours:
            (newData['employmentHours' + i] !== undefined && newData['employmentHours' + i]) || '',
          employmentAddress1:
            (newData['employmentAddress1' + i] !== undefined &&
              newData['employmentAddress1' + i]) ||
            '',
          employmentAddress2:
            (newData['employmentAddress2' + i] !== undefined &&
              newData['employmentAddress2' + i]) ||
            '',
          employmentCity:
            (newData['employmentCity' + i] !== undefined && newData['employmentCity' + i]) || '',
          employmentState:
            (newData['employmentState' + i] !== undefined && newData['employmentState' + i]) || '',
          employmentZip:
            (newData['employmentZip' + i] !== undefined && newData['employmentZip' + i]) || '',
          employmentEmploymentClassification:
            (newData['employmentEmploymentClassification' + i] !== undefined &&
              newData['employmentEmploymentClassification' + i]) ||
            '',
          employmentEmploymentType:
            (newData['employmentEmploymentType' + i] !== undefined &&
              newData['employmentEmploymentType' + i]) ||
            '',
          employmentEmploymentHistoryId:
            (newData.employers != null &&
              newData.employers.length > i &&
              newData.employers[i]['employmentEmploymentHistoryId']) ||
            0,
          employmentEmploymentHistoryAddressId:
            (newData.employers != null &&
              newData.employers.length > i &&
              newData.employers[i]['employmentEmploymentHistoryAddressId']) ||
            0,
          currentStatus:
            (newData.employers != null &&
              newData.employers.length > i &&
              newData.employers[i]['currentStatus']) ||
            0,
        };

        employers[i] = employer;
      }

      newData.employers = employers;
    }

    if (
      id === 'income' &&
      (attribute.includes('incomeSource') ||
        attribute.includes('annualIncome') ||
        attribute.includes('additionalJobCount'))
    ) {
      let additionalJobs = [];
      let incomeSource = {};

      for (let i = 0; i < newData.additionalJobCount; i++) {
        incomeSource = {
          incomeSource:
            (newData['incomeSource' + i] !== undefined && newData['incomeSource' + i]) || '',
          annualIncome:
            (newData['annualIncome' + i] !== undefined && newData['annualIncome' + i]) || '',
        };
        additionalJobs[i] = incomeSource;
      }

      newData.additionalJobs = additionalJobs;
    }

    updateForm(newData, this.getDependentAttributes(attribute));
  }, 250);

  getDependentAttributes = (attribute) => {
    switch (attribute) {
      case 'currentHouseholdSizeCorrect':
        return [
          'dependentName0',
          'dependentLastName0',
          'dependentRelationship0',
          'dependentBirthdate0',
          'dependentName1',
          'dependentLastName1',
          'dependentRelationship1',
          'dependentBirthdate1',
          'dependentName2',
          'dependentLastName2',
          'dependentRelationship2',
          'dependentBirthdate2',
          'dependentName3',
          'dependentLastName3',
          'dependentRelationship3',
          'dependentBirthdate3',
          'dependentName4',
          'dependentLastName4',
          'dependentRelationship4',
          'dependentBirthdate4',
          'dependentName5',
          'dependentLastName5',
          'dependentRelationship5',
          'dependentBirthdate5',
          'dependentName6',
          'dependentLastName6',
          'dependentRelationship6',
          'dependentBirthdate6',
          'dependentName7',
          'dependentLastName7',
          'dependentRelationship7',
          'dependentBirthdate7',
          'dependentName8',
          'dependentLastName8',
          'dependentRelationship8',
          'dependentBirthdate8',
          'dependentName9',
          'dependentLastName9',
          'dependentRelationship9',
          'dependentBirthdate9',
          'dependentName10',
          'dependentLastName10',
          'dependentRelationship10',
          'dependentBirthdate10',
        ];
      case 'householdSize':
        return [
          'dependentName0',
          'dependentLastName0',
          'dependentRelationship0',
          'dependentBirthdate0',
          'dependentName1',
          'dependentLastName1',
          'dependentRelationship1',
          'dependentBirthdate1',
          'dependentName2',
          'dependentLastName2',
          'dependentRelationship2',
          'dependentBirthdate2',
          'dependentName3',
          'dependentLastName3',
          'dependentRelationship3',
          'dependentBirthdate3',
          'dependentName4',
          'dependentLastName4',
          'dependentRelationship4',
          'dependentBirthdate4',
          'dependentName5',
          'dependentLastName5',
          'dependentRelationship5',
          'dependentBirthdate5',
          'dependentName6',
          'dependentLastName6',
          'dependentRelationship6',
          'dependentBirthdate6',
          'dependentName7',
          'dependentLastName7',
          'dependentRelationship7',
          'dependentBirthdate7',
          'dependentName8',
          'dependentLastName8',
          'dependentRelationship8',
          'dependentBirthdate8',
          'dependentName9',
          'dependentLastName9',
          'dependentRelationship9',
          'dependentBirthdate9',
          'dependentName10',
          'dependentLastName10',
          'dependentRelationship10',
          'dependentBirthdate10',
        ];
      case 'employmentSizeCorrect':
        return [
          'employmentEmploymentClassification0',
          'employmentEmploymentType0',
          'employmentEmployerName0',
          'employmentJobTitle0',
          'employmentStartDate0',
          'employmentEndDate0',
          'employmentEin0',
          'employmentPayPeriods0',
          'employmentHours0',
          'employmentAddress10',
          'employmentAddress20',
          'employmentCity0',
          'employmentState0',
          'employmentZip0',
          'employmentEmploymentClassification1',
          'employmentEmploymentType1',
          'employmentEmployerName1',
          'employmentJobTitle1',
          'employmentStartDate1',
          'employmentEndDate1',
          'employmentEin1',
          'employmentPayPeriods1',
          'employmentHours1',
          'employmentAddress11',
          'employmentAddress21',
          'employmentCity1',
          'employmentState1',
          'employmentZip1',
          'employmentEmploymentClassification2',
          'employmentEmploymentType2',
          'employmentEmployerName2',
          'employmentJobTitle2',
          'employmentStartDate2',
          'employmentEndDate2',
          'employmentEin2',
          'employmentPayPeriods2',
          'employmentHours2',
          'employmentAddress12',
          'employmentAddress22',
          'employmentCity2',
          'employmentState2',
          'employmentZip2',
          'employmentEmploymentClassification3',
          'employmentEmploymentType3',
          'employmentEmployerName3',
          'employmentJobTitle3',
          'employmentStartDate3',
          'employmentEndDate3',
          'employmentEin3',
          'employmentPayPeriods3',
          'employmentHours3',
          'employmentAddress13',
          'employmentAddress23',
          'employmentCity3',
          'employmentState3',
          'employmentZip3',
          'employmentEmploymentClassification4',
          'employmentEmploymentType4',
          'employmentEmployerName4',
          'employmentJobTitle4',
          'employmentStartDate4',
          'employmentEndDate4',
          'employmentEin4',
          'employmentPayPeriods4',
          'employmentHours4',
          'employmentAddress14',
          'employmentAddress24',
          'employmentCity4',
          'employmentState4',
          'employmentZip4',
          'employmentEmploymentClassification5',
          'employmentEmploymentType5',
          'employmentEmployerName5',
          'employmentJobTitle5',
          'employmentStartDate5',
          'employmentEndDate5',
          'employmentEin5',
          'employmentPayPeriods5',
          'employmentHours5',
          'employmentAddress15',
          'employmentAddress25',
          'employmentCity5',
          'employmentState5',
          'employmentZip5',
          'employmentEmploymentClassification6',
          'employmentEmploymentType6',
          'employmentEmployerName6',
          'employmentJobTitle6',
          'employmentStartDate6',
          'employmentEndDate6',
          'employmentEin6',
          'employmentPayPeriods6',
          'employmentHours6',
          'employmentAddress16',
          'employmentAddress26',
          'employmentCity6',
          'employmentState6',
          'employmentZip6',
          'employmentEmploymentClassification7',
          'employmentEmploymentType7',
          'employmentEmployerName7',
          'employmentJobTitle7',
          'employmentStartDate7',
          'employmentEndDate7',
          'employmentEin7',
          'employmentPayPeriods7',
          'employmentHours7',
          'employmentAddress17',
          'employmentAddress27',
          'employmentCity7',
          'employmentState7',
          'employmentZip7',
          'employmentEmploymentClassification8',
          'employmentEmploymentType8',
          'employmentEmployerName8',
          'employmentJobTitle8',
          'employmentStartDate8',
          'employmentEndDate8',
          'employmentEin8',
          'employmentPayPeriods8',
          'employmentHours8',
          'employmentAddress18',
          'employmentAddress28',
          'employmentCity8',
          'employmentState8',
          'employmentZip8',
          'employmentEmploymentClassification9',
          'employmentEmploymentType9',
          'employmentEmployerName9',
          'employmentJobTitle9',
          'employmentStartDate9',
          'employmentEndDate9',
          'employmentEin9',
          'employmentPayPeriods9',
          'employmentHours9',
          'employmentAddress19',
          'employmentAddress29',
          'employmentCity9',
          'employmentState9',
          'employmentZip9',
          'employmentEmploymentClassification10',
          'employmentEmploymentType10',
          'employmentEmployerName10',
          'employmentJobTitle10',
          'employmentStartDate10',
          'employmentEndDate10',
          'employmentEin10',
          'employmentPayPeriods10',
          'employmentHours10',
          'employmentAddress110',
          'employmentAddress210',
          'employmentCity10',
          'employmentState10',
          'employmentZip10',
        ];
      case 'employmentNewEmployerAmount':
        return [
          'employmentEmploymentClassification0',
          'employmentEmploymentType0',
          'employmentEmployerName0',
          'employmentJobTitle0',
          'employmentStartDate0',
          'employmentEndDate0',
          'employmentEin0',
          'employmentPayPeriods0',
          'employmentHours0',
          'employmentAddress10',
          'employmentAddress20',
          'employmentCity0',
          'employmentState0',
          'employmentZip0',
          'employmentEmploymentClassification1',
          'employmentEmploymentType1',
          'employmentEmployerName1',
          'employmentJobTitle1',
          'employmentStartDate1',
          'employmentEndDate1',
          'employmentEin1',
          'employmentPayPeriods1',
          'employmentHours1',
          'employmentAddress11',
          'employmentAddress21',
          'employmentCity1',
          'employmentState1',
          'employmentZip1',
          'employmentEmploymentClassification2',
          'employmentEmploymentType2',
          'employmentEmployerName2',
          'employmentJobTitle2',
          'employmentStartDate2',
          'employmentEndDate2',
          'employmentEin2',
          'employmentPayPeriods2',
          'employmentHours2',
          'employmentAddress12',
          'employmentAddress22',
          'employmentCity2',
          'employmentState2',
          'employmentZip2',
          'employmentEmploymentClassification3',
          'employmentEmploymentType3',
          'employmentEmployerName3',
          'employmentJobTitle3',
          'employmentStartDate3',
          'employmentEndDate3',
          'employmentEin3',
          'employmentPayPeriods3',
          'employmentHours3',
          'employmentAddress13',
          'employmentAddress23',
          'employmentCity3',
          'employmentState3',
          'employmentZip3',
          'employmentEmploymentClassification4',
          'employmentEmploymentType4',
          'employmentEmployerName4',
          'employmentJobTitle4',
          'employmentStartDate4',
          'employmentEndDate4',
          'employmentEin4',
          'employmentPayPeriods4',
          'employmentHours4',
          'employmentAddress14',
          'employmentAddress24',
          'employmentCity4',
          'employmentState4',
          'employmentZip4',
          'employmentEmploymentClassification5',
          'employmentEmploymentType5',
          'employmentEmployerName5',
          'employmentJobTitle5',
          'employmentStartDate5',
          'employmentEndDate5',
          'employmentEin5',
          'employmentPayPeriods5',
          'employmentHours5',
          'employmentAddress15',
          'employmentAddress25',
          'employmentCity5',
          'employmentState5',
          'employmentZip5',
          'employmentEmploymentClassification6',
          'employmentEmploymentType6',
          'employmentEmployerName6',
          'employmentJobTitle6',
          'employmentStartDate6',
          'employmentEndDate6',
          'employmentEin6',
          'employmentPayPeriods6',
          'employmentHours6',
          'employmentAddress16',
          'employmentAddress26',
          'employmentCity6',
          'employmentState6',
          'employmentZip6',
          'employmentEmploymentClassification7',
          'employmentEmploymentType7',
          'employmentEmployerName7',
          'employmentJobTitle7',
          'employmentStartDate7',
          'employmentEndDate7',
          'employmentEin7',
          'employmentPayPeriods7',
          'employmentHours7',
          'employmentAddress17',
          'employmentAddress27',
          'employmentCity7',
          'employmentState7',
          'employmentZip7',
          'employmentEmploymentClassification8',
          'employmentEmploymentType8',
          'employmentEmployerName8',
          'employmentJobTitle8',
          'employmentStartDate8',
          'employmentEndDate8',
          'employmentEin8',
          'employmentPayPeriods8',
          'employmentHours8',
          'employmentAddress18',
          'employmentAddress28',
          'employmentCity8',
          'employmentState8',
          'employmentZip8',
          'employmentEmploymentClassification9',
          'employmentEmploymentType9',
          'employmentEmployerName9',
          'employmentJobTitle9',
          'employmentStartDate9',
          'employmentEndDate9',
          'employmentEin9',
          'employmentPayPeriods9',
          'employmentHours9',
          'employmentAddress19',
          'employmentAddress29',
          'employmentCity9',
          'employmentState9',
          'employmentZip9',
          'employmentEmploymentClassification10',
          'employmentEmploymentType10',
          'employmentEmployerName10',
          'employmentJobTitle10',
          'employmentStartDate10',
          'employmentEndDate10',
          'employmentEin10',
          'employmentPayPeriods10',
          'employmentHours10',
          'employmentAddress110',
          'employmentAddress210',
          'employmentCity10',
          'employmentState10',
          'employmentZip10',
        ];
      case 'haveAdditionalJobs':
        return [
          'haveAdditionalJobs',
          'incomeSource0',
          'annualIncome0',
          'incomeSource1',
          'annualIncome1',
          'incomeSource2',
          'annualIncome2',
          'incomeSource3',
          'annualIncome3',
          'incomeSource4',
          'annualIncome4',
          'incomeSource5',
          'annualIncome5',
          'incomeSource6',
          'annualIncome6',
          'incomeSource7',
          'annualIncome7',
          'incomeSource8',
          'annualIncome8',
          'incomeSource9',
          'annualIncome9',
          'incomeSource10',
          'annualIncome10',
          'additionalJobs',
          'additionalJobCount',
        ];
      case 'additionalJobsCount':
        return [
          'incomeSource0',
          'annualIncome0',
          'incomeSource1',
          'annualIncome1',
          'incomeSource2',
          'annualIncome2',
          'incomeSource3',
          'annualIncome3',
          'incomeSource4',
          'annualIncome4',
          'incomeSource5',
          'annualIncome5',
          'incomeSource6',
          'annualIncome6',
          'incomeSource7',
          'annualIncome7',
          'incomeSource8',
          'annualIncome8',
          'incomeSource9',
          'annualIncome9',
          'incomeSource10',
          'annualIncome10',
          'additionalJobs',
          'additionalJobCount',
        ];
      default:
        return [attribute];
    }
  };

  handleTabChange = (index) => {
    this.props.validateTab(Object.keys(tabs)[this.state.current]);
    this.setState({ current: index });
  };

  sectionValidationStatus(section) {
    const { errors } = this.props;
    let exists = false;
    for (let i = 0; i < tabs[section].length; i++) {
      let attribute = tabs[section][i];
      let attributeStr = `[${attribute}]`;

      if (safeAccess(errors, attribute) || safeAccess(errors, attributeStr)) {
        return 'danger';
      } else if (safeAccess(errors, attributeStr) === false) {
        exists = true;
      }
    }
    return exists ? 'success' : null;
  }

  reviewValidationStatus = () => {
    const marriage = this.sectionValidationStatus('marriage');
    const household = this.sectionValidationStatus('household');
    const education = this.sectionValidationStatus('education');
    const employment = this.sectionValidationStatus('employment');
    const income = this.sectionValidationStatus('income');

    if (
      marriage === 'success' &&
      household === 'success' &&
      education === 'success' &&
      employment === 'success' &&
      income === 'success'
    ) {
      return 'success';
    } else {
      return null;
    }
  };

  render() {
    const { data, errors, hasResults, request } = this.props;

    return (
      <>
        <AsyncLoader request={request}>
          <>
            {safeAccess(errors, 'generalErrors')
              ? errors.generalErrors.map((e, i) => (
                  <Alert key={i} brand="danger">
                    {e.detail}
                  </Alert>
                ))
              : null}
            {/* TODO: Fix AsyncLoader so that it doesn't need this check for data */}
            {data && (
              <Workflow
                tabs={[
                  <TabMarriage
                    key="1"
                    id="marriage"
                    data={data}
                    errors={errors}
                    brand={this.sectionValidationStatus('marriage')}
                    name="Marriage"
                    handleChange={this.handleChange}
                  />,
                  <TabHousehold
                    key="2"
                    id="household"
                    data={data}
                    errors={errors}
                    brand={this.sectionValidationStatus('household')}
                    name="Household"
                    handleChange={this.handleChange}
                  />,
                  <TabEducation
                    key="3"
                    id="education"
                    data={data}
                    errors={errors}
                    brand={this.sectionValidationStatus('education')}
                    name="Education"
                    handleChange={this.handleChange}
                  />,
                  <TabEmployment
                    key="4"
                    id="employment"
                    data={data}
                    errors={errors}
                    brand={this.sectionValidationStatus('employment')}
                    name="Employment"
                    handleChange={this.handleChange}
                    addEmployer={this.addEmployer}
                  />,
                  <TabIncome
                    key="4"
                    id="income"
                    data={data}
                    errors={errors}
                    brand={this.sectionValidationStatus('income')}
                    name="Income"
                    handleChange={this.handleChange}
                  />,
                  <TabReview
                    key="5"
                    id="review"
                    data={data}
                    errors={errors}
                    brand={this.reviewValidationStatus()}
                    name="Review"
                    handleChange={this.handleChange}
                  />,
                  <TabResults key="6" brand={hasResults ? 'success' : null} name="Results" />,
                ]}
                handleTabChange={this.handleTabChange}
                next
                prev
                finalPageLocksFlow
                finalPageCheck={this.reviewValidationStatus}
                penUltimateButton={'Submit'}
              />
            )}
          </>
        </AsyncLoader>
      </>
    );
  }
}

const tabs = {
  marriage: [
    'clientMarriageStatusCorrect',
    'currentlyEngaged',
    'initialMarriageStatus',
    'marriageDate',
    'spouseHasStudentLoans',
    'spouseIncome',
    'spouseLoanBalance',
    'spousePayPeriods',
    'spouseLoanDeferment',
    'spouseLoanForearance',
    'spouseLoanRepayment',
    'spouseLoanOther',
    'taxFileType',
  ],
  household: [
    'currentHouseholdSizeCorrect',
    'householdSize',
    'dependents',
    'initialHouseholdSize',
    'dependentName0',
    'dependentLastName0',
    'dependentRelationship0',
    'dependentBirthdate0',
    'dependentName1',
    'dependentLastName1',
    'dependentRelationship1',
    'dependentBirthdate1',
    'dependentName2',
    'dependentLastName2',
    'dependentRelationship2',
    'dependentBirthdate2',
    'dependentName3',
    'dependentLastName3',
    'dependentRelationship3',
    'dependentBirthdate3',
    'dependentName4',
    'dependentLastName4',
    'dependentRelationship4',
    'dependentBirthdate4',
    'dependentName5',
    'dependentLastName5',
    'dependentRelationship5',
    'dependentBirthdate5',
    'dependentName6',
    'dependentLastName6',
    'dependentRelationship6',
    'dependentBirthdate6',
    'dependentName7',
    'dependentLastName7',
    'dependentRelationship7',
    'dependentBirthdate7',
    'dependentName8',
    'dependentLastName8',
    'dependentRelationship8',
    'dependentBirthdate8',
    'dependentName9',
    'dependentLastName9',
    'dependentRelationship9',
    'dependentBirthdate9',
    'dependentName10',
    'dependentLastName10',
    'dependentRelationship10',
    'dependentBirthdate10',
  ],
  education: [
    'borrowMore',
    'borrowMoreAmount',
    'borrowMoreExpectedIncome',
    'borrowMoreGraduationDate',
    'creditHours',
    'degreeSpecialty',
    'degreeType',
    'returnToSchool',
    'returnToSchoolDate',
  ],
  employment: [
    'employmentSizeCorrect',
    'employmentNewEmployerAmount',
    'employmentEmploymentClassification0',
    'employmentEmploymentType0',
    'employmentEmployerName0',
    'employmentJobTitle0',
    'employmentStartDate0',
    'employmentEndDate0',
    'employmentEin0',
    'employmentPayPeriods0',
    'employmentHours0',
    'employmentAddress10',
    'employmentAddress20',
    'employmentCity0',
    'employmentState0',
    'employmentZip0',
    'employmentEmploymentClassification1',
    'employmentEmploymentType1',
    'employmentEmployerName1',
    'employmentJobTitle1',
    'employmentStartDate1',
    'employmentEndDate1',
    'employmentEin1',
    'employmentPayPeriods1',
    'employmentHours1',
    'employmentAddress11',
    'employmentAddress21',
    'employmentCity1',
    'employmentState1',
    'employmentZip1',
    'employmentEmploymentClassification2',
    'employmentEmploymentType2',
    'employmentEmployerName2',
    'employmentJobTitle2',
    'employmentStartDate2',
    'employmentEndDate2',
    'employmentEin2',
    'employmentPayPeriods2',
    'employmentHours2',
    'employmentAddress12',
    'employmentAddress22',
    'employmentCity2',
    'employmentState2',
    'employmentZip2',
    'employmentEmploymentClassification3',
    'employmentEmploymentType3',
    'employmentEmployerName3',
    'employmentJobTitle3',
    'employmentStartDate3',
    'employmentEndDate3',
    'employmentEin3',
    'employmentPayPeriods3',
    'employmentHours3',
    'employmentAddress13',
    'employmentAddress23',
    'employmentCity3',
    'employmentState3',
    'employmentZip3',
    'employmentEmploymentClassification4',
    'employmentEmploymentType4',
    'employmentEmployerName4',
    'employmentJobTitle4',
    'employmentStartDate4',
    'employmentEndDate4',
    'employmentEin4',
    'employmentPayPeriods4',
    'employmentHours4',
    'employmentAddress14',
    'employmentAddress24',
    'employmentCity4',
    'employmentState4',
    'employmentZip4',
    'employmentEmploymentClassification5',
    'employmentEmploymentType5',
    'employmentEmployerName5',
    'employmentJobTitle5',
    'employmentStartDate5',
    'employmentEndDate5',
    'employmentEin5',
    'employmentPayPeriods5',
    'employmentHours5',
    'employmentAddress15',
    'employmentAddress25',
    'employmentCity5',
    'employmentState5',
    'employmentZip5',
    'employmentEmploymentClassification6',
    'employmentEmploymentType6',
    'employmentEmployerName6',
    'employmentJobTitle6',
    'employmentStartDate6',
    'employmentEndDate6',
    'employmentEin6',
    'employmentPayPeriods6',
    'employmentHours6',
    'employmentAddress16',
    'employmentAddress26',
    'employmentCity6',
    'employmentState6',
    'employmentZip6',
    'employmentEmploymentClassification7',
    'employmentEmploymentType7',
    'employmentEmployerName7',
    'employmentJobTitle7',
    'employmentStartDate7',
    'employmentEndDate7',
    'employmentEin7',
    'employmentPayPeriods7',
    'employmentHours7',
    'employmentAddress17',
    'employmentAddress27',
    'employmentCity7',
    'employmentState7',
    'employmentZip7',
    'employmentEmploymentClassification8',
    'employmentEmploymentType8',
    'employmentEmployerName8',
    'employmentJobTitle8',
    'employmentStartDate8',
    'employmentEndDate8',
    'employmentEin8',
    'employmentPayPeriods8',
    'employmentHours8',
    'employmentAddress18',
    'employmentAddress28',
    'employmentCity8',
    'employmentState8',
    'employmentZip8',
    'employmentEmploymentClassification9',
    'employmentEmploymentType9',
    'employmentEmployerName9',
    'employmentJobTitle9',
    'employmentStartDate9',
    'employmentEndDate9',
    'employmentEin9',
    'employmentPayPeriods9',
    'employmentHours9',
    'employmentAddress19',
    'employmentAddress29',
    'employmentCity9',
    'employmentState9',
    'employmentZip9',
    'employmentEmploymentClassification10',
    'employmentEmploymentType10',
    'employmentEmployerName10',
    'employmentJobTitle10',
    'employmentStartDate10',
    'employmentEndDate10',
    'employmentEin10',
    'employmentPayPeriods10',
    'employmentHours10',
    'employmentAddress110',
    'employmentAddress210',
    'employmentCity10',
    'employmentState10',
    'employmentZip10',
  ],
  income: [
    'agi',
    'haveAdditionalJobs',
    'incomeSource0',
    'annualIncome0',
    'incomeSource1',
    'annualIncome1',
    'incomeSource2',
    'annualIncome2',
    'incomeSource3',
    'annualIncome3',
    'incomeSource4',
    'annualIncome4',
    'incomeSource5',
    'annualIncome5',
    'incomeSource6',
    'annualIncome6',
    'incomeSource7',
    'annualIncome7',
    'incomeSource8',
    'annualIncome8',
    'incomeSource9',
    'annualIncome9',
    'incomeSource10',
    'annualIncome10',
    'additionalJobs',
    'additionalJobCount',
  ],
  results: [],
};

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.renewal;
  return {
    id: getUserId(state),
    data: isEqual(form, {}) ? null : form,
    errors: errors,
    hasResults: state.advisor.meta.cacheIsCurrent, // todo => renewal info
    request: requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(renewalLoadResource(id));
    await dispatch(renewalCopyResourceToForm(id));
  },
  updateForm: (data, attributes) => {
    // dispatch(advisorInvalidateCache()); // todo => renewal info
    dispatch(renewalHandleFormChange(data, attributes));
  },
  validateTab: async (tab) => {
    if (tab === 'employment') {
      await dispatch(renewalEmploymentValidation());
    } else {
      await dispatch(renewalValidateAttributes(tabs[tab]));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewalWorkflow);
