import { FormValidator } from '../../../utils';

export class RenewalValidator extends FormValidator {
  //#region Marriage
  clientMarriageStatusCorrect(validator) {
    validator.isRequired();
  }

  currentlyEngaged(validator, state) {
    if (state.clientCurrentlyMarried === 'N') {
      validator.isRequired();
    }
  }

  marriageDate(validator, state) {
    if (state.currentlyEngaged === 'Y') {
      validator.validDateRequiredAndAfterToday();
    }
  }

  spouseHasStudentLoans(validator, state) {
    if (state.clientCurrentlyMarried === 'Y') {
      validator.isRequired();
    }
  }

  spouseIncome(validator, state) {
    if (state.clientCurrentlyMarried === 'Y') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(0);
    }
  }

  spouseLoanBalance(validator, state) {
    if (state.spouseHasStudentLoans === 'Y') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  spousePayPeriods(validator, state) {
    if (state.clientCurrentlyMarried === 'Y') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(0);
    }
  }

  taxFileType(validator, state) {
    //if (state.clientCurrentlyMarried === 'Y') {
    validator.isRequired();
    //}
  }

  //#endregion

  //#region HouseholdQuestions

  clientHouseholdSizeCorrect(validator) {
    validator.isRequired();
  }

  householdSize(validator, state) {
    if (state.clientHouseholdSizeCorrect === 'N') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(0);
    }
  }

  dependentName0(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 0) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 0)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName0(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 0) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 0)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship0(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 0) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 0)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate0(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 0) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 0)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName1(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 1) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 1)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName1(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 1) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 1)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship1(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 1) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 1)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate1(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 1) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 1)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName2(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 2) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 2)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName2(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 2) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 2)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship2(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 2) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 2)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate2(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 2) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 2)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName3(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 3) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 3)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName3(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 3) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 3)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship3(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 3) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 3)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate3(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 3) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 3)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName4(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 4) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 4)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName4(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 4) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 4)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship4(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 4) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 4)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate4(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 4) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 4)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName5(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 5) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 5)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName5(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 5) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 5)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship5(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 5) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 5)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate5(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 5) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 5)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName6(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 6) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 6)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName6(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 6) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 6)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship6(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 6) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 6)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate6(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 6) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 6)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName7(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 7) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 7)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName7(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 7) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 7)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship7(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 7) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 7)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate7(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 7) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 7)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName8(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 8) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 8)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName8(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 8) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 8)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship8(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 8) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 8)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate8(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 8) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 8)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName9(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 9) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 9)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName9(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 9) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 9)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship9(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 9) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 9)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate9(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 9) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 9)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  dependentName10(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 10) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 10)
    ) {
      validator.isRequired();
      validator.hasMaxLength(20);
    }
  }

  dependentLastName10(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 10) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 10)
    ) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  dependentRelationship10(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 10) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 10)
    ) {
      validator.isRequired();
    }
  }

  dependentBirthdate10(validator, state) {
    if (
      ((state.clientHouseholdSizeCorrect === undefined ||
        state.clientHouseholdSizeCorrect === 'Y') &&
        state.householdSize > 10) ||
      (state.clientHouseholdSizeCorrect === 'N' && state.householdSize > 10)
    ) {
      validator.isRequired();
      validator.validDate();
    }
  }

  //#endregion

  //#region EducationQuestions
  returnToSchool(validator) {
    validator.isRequired();
  }

  returnToSchoolDate(validator, state) {
    if (state.returnToSchool === 'Y') {
      validator.isRequired();
      validator.validDate();
    }
  }

  degreeType(validator, state) {
    if (state.returnToSchool === 'Y') {
      validator.isRequired();
    }
  }

  degreeSpecialty(validator, state) {
    if (state.returnToSchool === 'Y') {
      validator.isRequired();
    }
  }

  creditHours(validator, state) {
    if (state.returnToSchool === 'Y') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  borrowMore(validator, state) {
    if (state.returnToSchool === 'Y') {
      validator.isRequired();
    }
  }

  borrowMoreAmount(validator, state) {
    if (state.borrowMore === 'Y') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(0);
    }
  }

  borrowMoreGraduationDate(validator, state) {
    if (state.borrowMore === 'Y') {
      validator.isRequired();
      validator.validDate();
      const today = new Date();
      today.setDate(today.getDate());
      validator.value = new Date(validator.value);
      validator.isGreaterThanOrEqualTo(today);
    }
  }

  borrowMoreExpectedIncome(validator, state) {
    if (state.borrowMore === 'Y') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(0);
    }
  }

  //#endregion

  //#region EmploymentQuestions

  employmentSizeCorrect(validator) {
    validator.isRequired();
  }

  employmentNewEmployerAmount(validator, state) {
    if (state.employmentSizeCorrect === 'N') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentEmploymentClassification0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
    }
  }

  employmentEmploymentType0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
    }
  }

  employmentEmployerName0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate0(validator, state) {
    if (state.employersCount > 0) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate0(validator, state) {
    if (state.employersCount > 0) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin0(validator, state) {
    if (state.employersCount > 0) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods0(validator, state) {
    if (state.employersCount > 0) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress10(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress20(validator, state) {
    if (state.employersCount > 0) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip0(validator, state) {
    if (state.employersCount > 0) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
    }
  }

  employmentEmploymentType1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
    }
  }

  employmentEmployerName1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate1(validator, state) {
    if (state.employersCount > 1) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate1(validator, state) {
    if (state.employersCount > 1) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin1(validator, state) {
    if (state.employersCount > 1) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods1(validator, state) {
    if (state.employersCount > 1) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress11(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress21(validator, state) {
    if (state.employersCount > 1) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip1(validator, state) {
    if (state.employersCount > 1) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
    }
  }

  employmentEmploymentType2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
    }
  }

  employmentEmployerName2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate2(validator, state) {
    if (state.employersCount > 2) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate2(validator, state) {
    if (state.employersCount > 2) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin2(validator, state) {
    if (state.employersCount > 2) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods2(validator, state) {
    if (state.employersCount > 2) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress12(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress22(validator, state) {
    if (state.employersCount > 2) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip2(validator, state) {
    if (state.employersCount > 2) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
    }
  }

  employmentEmploymentType3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
    }
  }

  employmentEmployerName3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate3(validator, state) {
    if (state.employersCount > 3) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate3(validator, state) {
    if (state.employersCount > 3) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin3(validator, state) {
    if (state.employersCount > 3) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods3(validator, state) {
    if (state.employersCount > 3) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress13(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress23(validator, state) {
    if (state.employersCount > 3) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip3(validator, state) {
    if (state.employersCount > 3) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
    }
  }

  employmentEmploymentType4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
    }
  }

  employmentEmployerName4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate4(validator, state) {
    if (state.employersCount > 4) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate4(validator, state) {
    if (state.employersCount > 4) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin4(validator, state) {
    if (state.employersCount > 4) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods4(validator, state) {
    if (state.employersCount > 4) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress14(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress24(validator, state) {
    if (state.employersCount > 4) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip4(validator, state) {
    if (state.employersCount > 4) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
    }
  }

  employmentEmploymentType5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
    }
  }

  employmentEmployerName5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate5(validator, state) {
    if (state.employersCount > 5) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate5(validator, state) {
    if (state.employersCount > 5) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin5(validator, state) {
    if (state.employersCount > 5) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods5(validator, state) {
    if (state.employersCount > 5) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress15(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress25(validator, state) {
    if (state.employersCount > 5) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip5(validator, state) {
    if (state.employersCount > 5) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
    }
  }

  employmentEmploymentType6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
    }
  }

  employmentEmployerName6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate6(validator, state) {
    if (state.employersCount > 6) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate6(validator, state) {
    if (state.employersCount > 6) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin6(validator, state) {
    if (state.employersCount > 6) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods6(validator, state) {
    if (state.employersCount > 6) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress16(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress26(validator, state) {
    if (state.employersCount > 6) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip6(validator, state) {
    if (state.employersCount > 6) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
    }
  }

  employmentEmploymentType7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
    }
  }

  employmentEmployerName7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate7(validator, state) {
    if (state.employersCount > 7) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate7(validator, state) {
    if (state.employersCount > 7) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin7(validator, state) {
    if (state.employersCount > 7) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods7(validator, state) {
    if (state.employersCount > 7) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress17(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress27(validator, state) {
    if (state.employersCount > 7) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip7(validator, state) {
    if (state.employersCount > 7) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
    }
  }

  employmentEmploymentType8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
    }
  }

  employmentEmployerName8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate8(validator, state) {
    if (state.employersCount > 8) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate8(validator, state) {
    if (state.employersCount > 8) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin8(validator, state) {
    if (state.employersCount > 8) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods8(validator, state) {
    if (state.employersCount > 8) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress18(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress28(validator, state) {
    if (state.employersCount > 8) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip8(validator, state) {
    if (state.employersCount > 8) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
    }
  }

  employmentEmploymentType9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
    }
  }

  employmentEmployerName9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate9(validator, state) {
    if (state.employersCount > 9) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate9(validator, state) {
    if (state.employersCount > 9) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin9(validator, state) {
    if (state.employersCount > 9) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods9(validator, state) {
    if (state.employersCount > 9) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress19(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress29(validator, state) {
    if (state.employersCount > 9) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip9(validator, state) {
    if (state.employersCount > 9) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  employmentEmploymentClassification10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
    }
  }

  employmentEmploymentType10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
    }
  }

  employmentEmployerName10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  employmentJobTitle10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
      validator.hasMaxLength(50);
    }
  }

  employmentStartDate10(validator, state) {
    if (state.employersCount > 10) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEndDate10(validator, state) {
    if (state.employersCount > 10) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validDate();
      }
    }
  }

  employmentEin10(validator, state) {
    if (state.employersCount > 10) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.hasMaxLength(10);
        validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
      }
    }
  }

  employmentPayPeriods10(validator, state) {
    if (state.employersCount > 10) {
      if (validator.value !== undefined && validator.value !== '') {
        validator.validNumber();
      }
    }
  }

  employmentHours10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
      validator.validNumber();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  employmentAddress110(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentAddress210(validator, state) {
    if (state.employersCount > 10) {
      validator.hasMaxLength(30);
    }
  }

  employmentCity10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
      validator.hasMaxLength(30);
    }
  }

  employmentState10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
      validator.hasMaxLength(2);
    }
  }

  employmentZip10(validator, state) {
    if (state.employersCount > 10) {
      validator.isRequired();
      validator.hasMaxLength(10);
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
  }

  //#endregion

  //#region IncomeQuestions

  agi(validator) {
    validator.isRequired();
  }

  haveAdditionalJobs(validator) {
    validator.isRequired();
  }

  additionalJobCount(validator, state) {
    if (state.haveAdditionalJobs === 'Y') {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource0(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 0) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome0(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 0) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource1(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 1) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome1(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 1) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource2(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 2) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome2(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 2) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource3(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 3) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome3(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 3) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource4(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 4) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome4(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 4) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource5(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 5) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome5(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 5) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource6(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 6) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome6(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 6) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource7(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 7) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome7(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 7) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource8(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 8) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome8(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 8) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource9(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 9) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome9(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 9) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  incomeSource10(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 10) {
      validator.isRequired();
      validator.hasMaxLength(250);
    }
  }

  annualIncome10(validator, state) {
    if (state.haveAdditionalJobs === 'Y' && state.additionalJobCount > 10) {
      validator.isRequired();
      validator.isGreaterThanOrEqualTo(1);
    }
  }

  //#endregion
}

export default RenewalValidator;
