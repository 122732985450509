import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  CardBody,
  CardText,
  Button,
  InputWrapper,
  InputCheckbox,
  InputLabel,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { formatRelative, parseISO } from 'date-fns';
import englishDefault from 'date-fns/locale/en-US/index';

import { FlexBetween } from '../../../root';

import { safeAccess, debounce, mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

import { authLoadPermissions } from '../../../auth';

import { todosLoadCollection } from '../../../todos/redux/operations';

import {
  selfServiceDocumentLoadDocuments,
  selfServiceDocumentUpdateAll,
  selfServiceDocumentUpdateParentFile,
  selfServiceDocumentUpdateResource,
} from '../../../self-service-document';

import FaxApproval from './fax-approval';
import EmpcertClientApproval from './empcert-client-approval.jsx';

import { ButtonSpacer } from '../../../accounts';

const InputWrapper2 = styled(InputWrapper)`
  color: ${(p) => p.theme.textPrimary};
`;

class StandardFax extends React.Component {
  static propTypes = {
    confirm: t.func.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    stepId: t.number.isRequired,
    todos: t.array,
    additionalInfoNeeded: t.bool,
    handleChange: t.func,
    reload: t.func.isRequired,
    load: t.func.isRequired,
    documents: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showCheckbox: false,
      redirectURI: '',
      goToInfo: false,
      showForm: false,
      showNewForm: false,
      documentId: '',
    };
    this.onConfirm = this.onConfirm.bind(this);
    this.goToInfo = this.goToInfo.bind(this);
  }

  componentDidMount() {
    const { load, stepId, updateToDosAndLoad, permissions, todos } = this.props;
    if (permissions.electronicEmpcertEnabled && (stepId === 253 || stepId === 107)) {
      const currentToDo = todos.find((a) => a.id === 2 || a.id === 4);
      // Check for todo of 2 or 4.  If found, need to reload Todos as well
      if (parseInt(safeAccess(currentToDo, 'id', 0)) > 0) {
        updateToDosAndLoad(stepId);
      } else {
        load(stepId);
      }
    } else {
      load(stepId);
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  onConfirm = async () => {
    await this.props.confirm(this.props.stepId);
    this.goToCurrentStep();
  };

  onUpdateDocumentStatus = async (id, action) => {
    const { documents = [] } = this.props;
    documents['current'][id].additionalData = action;
    await this.props.updateDocumentStatus(id);
    this.goToCurrentStep();
  };

  goToInfo(e) {
    this.props.handleChange(e.type);
  }

  checkStepCompleted = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const forgivenessStep = permissions.forgivenessSteps.find((a) => a.id === stepMapping);

    let retVal = false;
    if (
      forgivenessStep !== undefined &&
      forgivenessStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      retVal = forgivenessStep.stepComplete;
      if (!retVal) {
        if (stepId === 103 || stepId === 104 || stepId === 105) {
          const currentToDo = todos.find((a) => a.id === 103 || a.id === 104 || a.id === 105);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (stepId === 106 || stepId === 107 || stepId === 108) {
          const currentToDo = todos.find((a) => a.id === 106 || a.id === 107 || a.id === 108);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (stepId === 111 || stepId === 112 || stepId === 113) {
          const currentToDo = todos.find((a) => a.id === 111 || a.id === 112 || a.id === 113);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (stepId === 252 || stepId === 253 || stepId === 254) {
          const currentToDo = todos.find((a) => a.id === 252 || a.id === 253 || a.id === 254);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (stepId === 255 || stepId === 256 || stepId === 257) {
          const currentToDo = todos.find((a) => a.id === 255 || a.id === 256 || a.id === 257);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        }
      }
    }
    return retVal;
  };

  getStepMapping = (id) => {
    let retVal = '';
    switch (id) {
      case 101:
        retVal = 'Welcome';
        break;
      case 102:
        retVal = 'ForbearanceP1';
        break;
      case 103:
      case 104:
      case 105:
        retVal = 'Consolidation';
        break;
      case 106:
      case 107:
      case 108:
        retVal = 'EmpCert';
        break;
      case 109:
        retVal = 'ForbearanceP2';
        break;
      case 110:
        retVal = 'Taxes';
        break;
      case 111:
      case 112:
      case 113:
        retVal = 'IDR';
        break;
      case 209:
        retVal = 'ProfileVerification';
        break;
      case 201:
        retVal = 'Questionnaire';
        break;
      case 203:
        retVal = 'Meeting';
        break;
      case 204:
      case 250:
        retVal = 'Fee';
        break;
      case 251:
        retVal = 'Forbearance';
        break;
      case 252:
      case 253:
      case 254:
        retVal = 'EmpCert';
        break;
      case 206:
      case 255:
      case 256:
      case 257:
        retVal = 'IDR';
        break;
      default:
        retVal = 'Overview';
        break;
    }
    return retVal;
  };

  checkNotYetAtStep = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const forgivenessStep = permissions.forgivenessSteps.find((a) => a.id === stepMapping);
    const currentStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);

    let retVal = false;
    if (
      forgivenessStep !== undefined &&
      forgivenessStep !== null &&
      currentStep !== undefined &&
      currentStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (forgivenessStep.id === currentStep.id) {
        if (stepId === 103 || stepId === 104 || stepId === 105) {
          const currentToDo = todos.find((a) => a.id === 103 || a.id === 104 || a.id === 105);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 106 || stepId === 107 || stepId === 108) {
          const currentToDo = todos.find((a) => a.id === 106 || a.id === 107 || a.id === 108);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 111 || stepId === 112 || stepId === 113) {
          const currentToDo = todos.find((a) => a.id === 111 || a.id === 112 || a.id === 113);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 252 || stepId === 253 || stepId === 254) {
          const currentToDo = todos.find((a) => a.id === 252 || a.id === 253 || a.id === 254);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 255 || stepId === 256 || stepId === 257) {
          const currentToDo = todos.find((a) => a.id === 255 || a.id === 256 || a.id === 257);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        }
      } else {
        retVal = !forgivenessStep.stepComplete && !forgivenessStep.stepCurrent;
      }
    }
    return retVal;
  };

  getCurrentToDo = () => {
    const { permissions, todos, forReducedIDR } = this.props;
    let currentToDo;

    if (permissions.forgivenessSteps !== undefined && permissions.forgivenessSteps !== null) {
      const forgivenessSteps = permissions.forgivenessSteps.filter((a) => a.stepCurrent);
      let forgivenessStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);

      if (
        forgivenessSteps !== undefined &&
        forgivenessSteps !== null &&
        forgivenessSteps.length > 1 &&
        forgivenessSteps.find((a) => window.location.pathname.includes(a.stepPath))
      ) {
        forgivenessStep = forgivenessSteps.find((a) =>
          window.location.pathname.includes(a.stepPath)
        );
      }
      if (
        forgivenessStep !== undefined &&
        forgivenessStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        currentToDo = todos.find(
          (a) =>
            (forgivenessStep.id === 'Consolidation' &&
              (a.id === 103 || a.id === 104 || a.id === 105)) ||
            (forgivenessStep.id === 'EmpCert' &&
              (a.id === 106 ||
                a.id === 107 ||
                a.id === 108 ||
                a.id === 252 ||
                a.id === 253 ||
                a.id === 254)) ||
            (forgivenessStep.id === 'IDR' &&
              (a.id === 111 ||
                a.id === 112 ||
                a.id === 113 ||
                a.id === 255 ||
                a.id === 256 ||
                a.id === 257))
        );
      }
    }

    if (
      permissions.enterpriseEmpcertSteps !== undefined &&
      permissions.enterpriseEmpcertSteps !== null
    ) {
      const enterpriseEmpCertSteps = permissions.enterpriseEmpcertSteps.filter(
        (a) => a.stepCurrent
      );
      let enterpriseEmpCertStep = permissions.enterpriseEmpcertSteps.find((a) => a.stepCurrent);

      if (
        enterpriseEmpCertSteps !== undefined &&
        enterpriseEmpCertSteps !== null &&
        enterpriseEmpCertSteps.length > 1 &&
        enterpriseEmpCertSteps.find((a) => window.location.pathname.includes(a.stepPath))
      ) {
        enterpriseEmpCertStep = enterpriseEmpCertSteps.find((a) =>
          window.location.pathname.includes(a.stepPath)
        );
      }
      if (
        enterpriseEmpCertStep !== undefined &&
        enterpriseEmpCertStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        currentToDo = todos.find(
          (a) =>
            a.id === 800 ||
            a.id === 801 ||
            a.id === 805 ||
            a.id === 810 ||
            a.id === 815 ||
            a.id === 820
        );
      }
    }

    if (permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null) {
      const reducedIdrSteps = permissions.reducedIdrSteps.filter((a) => a.stepCurrent);
      let reducedIdrStep = permissions.reducedIdrSteps.find((a) => a.stepCurrent);

      if (
        reducedIdrSteps !== undefined &&
        reducedIdrSteps !== null &&
        reducedIdrSteps.length > 1 &&
        reducedIdrSteps.find((a) => window.location.pathname.includes(a.stepPath))
      ) {
        reducedIdrStep = reducedIdrSteps.find((a) => window.location.pathname.includes(a.stepPath));
      }
      if (
        forReducedIDR &&
        reducedIdrStep !== undefined &&
        reducedIdrStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        currentToDo = todos.find(
          (a) =>
            a.id === 1100 ||
            a.id === 1200 ||
            a.id === 1300 ||
            a.id === 1400 ||
            a.id === 1500 ||
            a.id === 1600 ||
            a.id === 1610 ||
            a.id === 1620 ||
            a.id === 1630
        );
      }
    }

    return currentToDo;
  };

  goToCurrentStep = () => {
    const { permissions, todos } = this.props;
    let retVal = '/benefits/forgiveness/overview';

    if (permissions.forgivenessSteps !== undefined && permissions.forgivenessSteps !== null) {
      const forgivenessSteps = permissions.forgivenessSteps.filter((a) => a.stepCurrent);
      let forgivenessStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);

      if (
        forgivenessSteps !== undefined &&
        forgivenessSteps !== null &&
        forgivenessSteps.length > 1 &&
        forgivenessSteps.find((a) => window.location.pathname.includes(a.stepPath))
      ) {
        forgivenessStep = forgivenessSteps.find((a) =>
          window.location.pathname.includes(a.stepPath)
        );
      }

      if (
        forgivenessStep !== undefined &&
        forgivenessStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (forgivenessStep.id === 'EmpCert' || forgivenessStep.id === 'IDR') {
          retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
          const currentToDo = todos.find(
            (a) =>
              (forgivenessStep.id === 'Consolidation' &&
                (a.id === 103 || a.id === 104 || a.id === 105)) ||
              (forgivenessStep.id === 'EmpCert' &&
                (a.id === 106 ||
                  a.id === 107 ||
                  a.id === 108 ||
                  a.id === 252 ||
                  a.id === 253 ||
                  a.id === 254)) ||
              (forgivenessStep.id === 'IDR' &&
                (a.id === 111 ||
                  a.id === 112 ||
                  a.id === 113 ||
                  a.id === 255 ||
                  a.id === 256 ||
                  a.id === 257))
          );
          if (currentToDo !== undefined && currentToDo !== null) {
            switch (currentToDo.id) {
              case 103:
              case 106:
              case 111:
              case 252:
              case 255:
                retVal += '/step-1';
                break;
              case 104:
              case 107:
              case 112:
              case 253:
              case 256:
                retVal += '/step-2';
                break;
              case 105:
              case 108:
              case 113:
              case 254:
              case 257:
                retVal += '/step-3';
                break;
              default:
                break;
            }
          }
        }
      } else {
        retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
      }
    }

    if (
      permissions.enterpriseEmpcertSteps !== undefined &&
      permissions.enterpriseEmpcertSteps !== null
    ) {
      let enterpriseEmpCertStep = permissions.enterpriseEmpcertSteps.find((a) => a.stepCurrent);
      if (
        enterpriseEmpCertStep !== undefined &&
        enterpriseEmpCertStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        const currentToDo = todos.find(
          (a) => a.id === 800 || a.id === 801 || a.id === 805 || a.id === 810 || a.id === 820
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 800:
              retVal = '/benefits/empcert/generate';
              break;
            case 801:
            case 810:
              retVal = '/benefits/empcert/submit';
              break;
            case 805:
              retVal = '/benefits/empcert/correction';
              break;
            case 815:
              retVal = '/benefits/empcert/check';
              break;
            case 820:
              retVal = '/benefits/empcert/complete';
              break;
          }
        }
      }
    }

    if (permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null) {
      const reducedIDRStep = permissions.reducedIdrSteps.find((a) => a.stepCurrent);
      if (
        reducedIDRStep !== undefined &&
        reducedIDRStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (reducedIDRStep.id === 'IDRReducedIDR') {
          retVal = '/benefits/reducedidr' + reducedIDRStep.stepPath;
          const currentToDo = todos.find(
            (a) =>
              reducedIDRStep.id === 'IDRReducedIDR' &&
              (a.id === 1200 ||
                a.id === 1300 ||
                a.id === 1400 ||
                a.id === 1600 ||
                a.id === 1610 ||
                a.id === 1620)
          );
          if (currentToDo !== undefined && currentToDo !== null) {
            switch (currentToDo.id) {
              case 1200:
              case 1600:
                retVal += '/step-1';
                break;
              case 1300:
              case 1610:
                retVal += '/step-2';
                break;
              case 1400:
              case 1620:
                retVal += '/step-3';
                break;
              default:
                break;
            }
          }
        } else {
          retVal = '/benefits/reducedidr' + reducedIDRStep.stepPath;
        }
      }
    }

    this.setState({ ...this.state, redirectURI: retVal });
  };

  showDocument = (documentId, status) => {
    const { permissions } = this.props;
    if (status === 'S' && permissions.electronicEmpcertEnabled) {
      this.setState({ ...this.state, showForm: false, showNewForm: true, documentId: documentId });
    } else {
      this.setState({ ...this.state, showForm: true, showNewForm: false, documentId: documentId });
    }
  };

  handleChange = debounce((formState, id, attributes) => {
    this.setState({ ...this.state, showCheckbox: !this.state.showCheckbox });
  }, 250);

  getDocumentList = () => {
    const { documents = [], permissions } = this.props;

    let documentBody = [];

    if (documents['current'] !== undefined) {
      Object.keys(documents['current']).forEach((a) => {
        let disabled =
          documents['current'][a].fileData === undefined ||
          documents['current'][a].fileData === null ||
          documents['current'][a].fileData === '';

        let paymentNeeded = false;

        let documentStatus = documents['current'][a].status;

        let documentStatusString = '';
        if (documentStatus !== null || documentStatus !== undefined) {
          switch (documentStatus) {
            case 'G':
              //documentStatusString = ' (Signed by Approver and Ready for Client Fax Approval)';
              documentStatusString = '';
              if (
                !permissions.subscriptionActive &&
                documents['current'][a].additionalData === 'Payment Needed'
              ) {
                disabled = true;
                documentStatusString = ' (Payment Needed)';
              }
              break;
            case 'X':
              documentStatusString = ' (Signed and Sent to Authorized Official)';
              disabled = true;
              break;
            case 'R':
              documentStatusString = ' (Employer Marked as Unqualified)';
              disabled = true;
              break;
            case 'M':
              //documentStatusString = ' (Marked as Manual mode)';
              if (
                !permissions.subscriptionActive &&
                documents['current'][a].additionalData === 'Payment Needed' &&
                documents['current'][a].uploadedToFiducius
              ) {
                disabled = true;
                documentStatusString = ' (Payment Needed)';
                paymentNeeded = true;
              } else {
                documentStatusString = '';
              }
              break;
            default:
              documentStatusString = '';
          }
        }

        documents['current'][a].status !== 'E' &&
          documentBody.push(
            <>
              <ButtonSpacer>
                <Button
                  brand={
                    documents['current'][a].sentToServicer || documents['current'][a].readyToSend
                      ? 'success'
                      : disabled
                      ? 'danger'
                      : 'info'
                  }
                  disabled={disabled}
                  onClick={() => this.showDocument(a, documents['current'][a].status)}
                >
                  View {documents['current'][a].description}
                  {documentStatusString}{' '}
                  {disabled === true &&
                    !paymentNeeded &&
                    documents['current'][a].employmentHistoryId !== null &&
                    documents['current'][a].status !== 'X' &&
                    documents['current'][a].status !== 'R' &&
                    documents['current'][a].status !== 'G' &&
                    '(Not Yet Uploaded)'}
                </Button>
              </ButtonSpacer>
            </>
          );
        documents['current'][a].status === 'E' &&
          documentBody.push(
            <>
              <ButtonSpacer>
                <Button
                  brand={
                    documents['current'][a].sentToServicer || documents['current'][a].readyToSend
                      ? 'success'
                      : disabled
                      ? 'danger'
                      : 'info'
                  }
                  onClick={() => this.onUpdateDocumentStatus(documents['current'][a].id, 'resend')}
                >
                  Approval Expired - Resend Email to Authorized Official for{' '}
                  {documents['current'][a].description}
                  {documentStatusString}{' '}
                </Button>
                {'  '}
                {!permissions.hasEnterpriseEmpCert ||
                  ((permissions.enterpriseEmpcertSteps === undefined ||
                    permissions.enterpriseEmpcertSteps === null) && (
                    <Button
                      brand={
                        documents['current'][a].sentToServicer ||
                        documents['current'][a].readyToSend
                          ? 'success'
                          : disabled
                          ? 'danger'
                          : 'info'
                      }
                      onClick={() =>
                        this.onUpdateDocumentStatus(documents['current'][a].id, 'manual')
                      }
                    >
                      Complete Physical Form for {documents['current'][a].description}
                      {documentStatusString}{' '}
                    </Button>
                  ))}
              </ButtonSpacer>
            </>
          );
      });
    }
    return documentBody;
  };

  closeForm = async (reloadDocs) => {
    if (reloadDocs) {
      const { reload, stepId } = this.props;
      await reload(stepId);

      this.setState({
        ...this.state,
        showForm: false,
        showNewForm: false,
        documentId: '',
      });
    } else {
      this.setState({
        ...this.state,
        showForm: false,
        showNewForm: false,
        documentId: '',
      });
    }
  };

  showConfirmation = () => {
    const { documents = [] } = this.props;

    let allowedConfirm = true;
    if (documents['current'] !== undefined) {
      if (Object.keys(documents['current']).length === 0) {
        allowedConfirm = false;
      } else {
        Object.keys(documents['current']).forEach((a) => {
          if (!(documents['current'][a].sentToServicer || documents['current'][a].readyToSend)) {
            allowedConfirm = false;
          }
        });
      }
    }
    return allowedConfirm;
  };

  showGoToNextStep = () => {
    const { documents = [] } = this.props;

    let allowedConfirm = true;
    if (documents['current'] !== undefined) {
      if (Object.keys(documents['current']).length === 0) {
        allowedConfirm = false;
      } else {
        Object.keys(documents['current']).forEach((a) => {
          if (!documents['current'][a].sentToServicer) {
            allowedConfirm = false;
          }
        });
      }
    }
    return allowedConfirm;
  };

  faxesInProcessing = () => {
    const { documents = [] } = this.props;

    let inProcessing = false;
    if (documents['current'] !== undefined) {
      if (Object.keys(documents['current']).length === 0) {
        inProcessing = false;
      } else {
        Object.keys(documents['current']).forEach((a) => {
          if (documents['current'][a].processing) {
            inProcessing = true;
          }
        });
      }
    }
    return inProcessing;
  };

  getTodaysDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return (today = yyyy + '-' + mm + '-' + dd + 'T00:00:00');
  };

  getDateToSend = () => {
    const { documents = [] } = this.props;
    const customRelativeLocale = {
      lastWeek: "'last",
      yesterday: "'yesterday",
      today: "'today",
      tomorrow: "'tomorrow",
      nextWeek: 'eeee',
      other: 'MMMM do',
    };
    let retVal;

    const customLocale = {
      ...englishDefault,
      formatRelative: (token, date, baseDate, options) => customRelativeLocale[token],
    };

    Object.keys(documents['current']).forEach((a) => {
      if (documents['current'][a].dateToSend !== undefined) {
        let dateToSend = new Date(documents['current'][a].dateToSend);
        let todayDate = new Date();

        if (todayDate < dateToSend) {
          retVal = documents['current'][a].dateToSend;
        } else {
          //return 'today' if the date to send is in the past.
          retVal = this.getTodaysDate();
        }
      }
    });

    if (retVal !== undefined) {
      retVal = formatRelative(this.adjustedMeetingTime(retVal), new Date(), {
        addSuffix: false,
        locale: customLocale,
      });
    }

    return retVal;
  };

  adjustedMeetingTime = (time) => {
    return parseISO(time);
  };

  render() {
    const { documents, stepId, permissions, forReducedIDR } = this.props;

    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      return (
        <>
          <AsyncLoader request={this.props.request}>
            <CardBody>
              <CardText>
                {this.faxesInProcessing() &&
                  stepId !== 256 &&
                  stepId !== 112 &&
                  stepId !== 1300 &&
                  stepId !== 1610 && (
                    <p>
                      Your fax is currently being processed. You will be notified when it has been
                      sent.
                    </p>
                  )}
                {this.faxesInProcessing() &&
                  (stepId === 256 || stepId === 112 || stepId === 1300 || stepId === 1610) && (
                    <p>
                      Your IDR application is scheduled to fax {this.getDateToSend()}
                      {this.getDateToSend() === 'today' &&
                        '. You will be notified when it has been sent.'}
                      {this.getDateToSend() !== 'today' && (
                        <ul>
                          <li>
                            The fax is scheduled for a future date to ensure your IDR application is
                            not submitted too early, which could potentially cause issues
                          </li>
                        </ul>
                      )}
                    </p>
                  )}
                {!this.faxesInProcessing() && (
                  <>
                    {documents['current'] !== undefined &&
                      permissions.electronicEmpcertEnabled &&
                      Object.keys(documents['current']).length > 0 && (
                        <>
                          <p>Below are the document(s) that you need to approve.</p>
                          <p>{this.getDocumentList()}</p>
                        </>
                      )}
                    {documents['current'] !== undefined &&
                      !permissions.electronicEmpcertEnabled &&
                      Object.keys(documents['current']).length > 0 && (
                        <>
                          <p>
                            Below are the document(s) that you need to approve as ready to be faxed
                            to your servicer.
                          </p>
                          <FlexBetween>{this.getDocumentList()}</FlexBetween>
                        </>
                      )}
                    {documents['current'] !== undefined &&
                      Object.keys(documents['current']).length === 0 && (
                        <>
                          There's no document for you to approve to fax here. That could be for one
                          of the following reasons:
                          <ul>
                            <li>You have documents that need electronically signed</li>
                            <li>You have documents that need uploaded</li>
                            <li>
                              You requested help from Fiducius because an electronically signed
                              document looked incorrect
                            </li>
                          </ul>
                        </>
                      )}

                    {documents['current'] !== undefined &&
                      Object.keys(documents['current']).length === 0 &&
                      forReducedIDR && (
                        <div className="row col-12">
                          <div className="col-8"></div>
                          <div className="col-4">
                            <InputWrapper>
                              <Button
                                brand="info"
                                onClick={() => {
                                  this.setState({ ...this.state, redirectURI: '/documents' });
                                }}
                              >
                                {'Go to My Documents'}
                              </Button>
                            </InputWrapper>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </CardText>
            </CardBody>
            {this.showGoToNextStep() && (
              <div className="row col-12">
                <div className="col-8">
                  <InputWrapper>
                    <InputLabel>You've completed this step.</InputLabel>
                  </InputWrapper>
                </div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="info" onClick={this.goToCurrentStep}>
                      {'Go to current step'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            )}
            {this.showConfirmation() && !this.showGoToNextStep() && !this.faxesInProcessing() && (
              <>
                <div className="row col-12">
                  <div className="col-12">
                    <InputWrapper2>
                      <InputCheckbox
                        name="allowConfirmation"
                        label="I am ready to confirm that I have followed all of the above steps and am ready to continue."
                        onChange={(e) =>
                          this.handleChange(
                            { allowConfirmation: e.target.value },
                            'allowConfirmation',
                            'allowConfirmation'
                          )
                        }
                      ></InputCheckbox>
                    </InputWrapper2>
                  </div>
                </div>
                <div className="row col-12">
                  <div className="col-8"></div>
                  <div className="col-4">
                    <InputWrapper>
                      <Button
                        id={'confirm'}
                        brand={'primary'}
                        onClick={this.onConfirm}
                        disabled={!this.state.showCheckbox}
                      >
                        {'Confirm'}
                      </Button>
                    </InputWrapper>
                  </div>
                </div>
              </>
            )}
          </AsyncLoader>
          {this.state.showForm && (
            <FaxApproval
              isOpen={this.state.showForm}
              onSubmit={() => {}}
              documentId={this.state.documentId}
              closeForm={this.closeForm}
              stepId={this.getCurrentToDo()}
            />
          )}
          {this.state.showNewForm && (
            <EmpcertClientApproval
              isOpen={this.state.showNewForm}
              onSubmit={() => {}}
              documentId={this.state.documentId}
              closeForm={this.closeForm}
              stepId={this.getCurrentToDo()}
            />
          )}
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.selfServiceDocument.requests.updateResource,
    state.selfServiceDocument.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
  documents: safeAccess(state, 'selfServiceDocument.cache'),
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(selfServiceDocumentLoadDocuments(id));
  },
  updateToDosAndLoad: async (id) => {
    await dispatch(selfServiceDocumentLoadDocuments(id));
    await dispatch(todosLoadCollection());
  },
  reload: async (id) => {
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
    await dispatch(selfServiceDocumentLoadDocuments(id));
  },
  confirm: async (id) => {
    if (id === 107 || id === 253 || id === 810) {
      await dispatch(selfServiceDocumentUpdateAll());
    } else {
      await dispatch(selfServiceDocumentUpdateParentFile());
    }
    await dispatch(selfServiceDocumentLoadDocuments(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
  updateDocumentStatus: async (id) => {
    await dispatch(selfServiceDocumentUpdateResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(StandardFax));
