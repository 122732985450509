import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Form,
  Header2,
  InputAddon,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputNumeric,
  InputRadio,
  InputSelect,
  InputWrapper,
  InputCheckbox,
} from 'fiducius-ui';

import { Fade } from '../../../routing';
import { convertIsoToSlash, useScrollToTopOnMount, safeAccess } from '../../../utils';

const WorkflowAlert = styled.div`
  & > .alert {
    margin-left: 0;
  }
`;

const TabMarriage = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your marital status</Header2>
      <InputWrapper error={!!errors.clientMarriageStatusCorrect}>
        <InputLabel>
          Our records indicate that you're {data.initialMarriageStatus}. Is that correct?
        </InputLabel>
        <InputError>{safeAccess(errors, 'initialMarriageStatus.detail')}</InputError>
        <InputRadio
          name="clientMarriageStatusCorrect"
          value="Y"
          label="Yes"
          checked={data.clientMarriageStatusCorrect === 'Y'}
        />
        <InputRadio
          name="clientMarriageStatusCorrect"
          value="N"
          label="No"
          checked={data.clientMarriageStatusCorrect === 'N'}
        />
      </InputWrapper>
      <Fade id="unmarriedGate" show={data.clientCurrentlyMarried === 'N'}>
        <InputWrapper error={!!errors.currentlyEngaged}>
          <InputLabel htmlFor="currentlyEngaged">Are you currently engaged?</InputLabel>
          <InputError>{safeAccess(errors, 'currentlyEngaged.detail')}</InputError>
          <InputRadio
            name="currentlyEngaged"
            value="Y"
            label="Yes"
            defaultChecked={data.currentlyEngaged === 'Y'}
          />
          <InputRadio
            name="currentlyEngaged"
            value="N"
            label="No"
            defaultChecked={data.currentlyEngaged === 'N'}
          />
        </InputWrapper>
        <Fade id="engagedGate" show={data.currentlyEngaged === 'Y'}>
          <InputWrapper error={!!errors.marriageDate}>
            <InputLabel htmlFor="marriageDate">On what date will you be married</InputLabel>
            <InputError>{safeAccess(errors, 'marriageDate.detail')}</InputError>
            <InputGroup>
              <InputMask
                defaultValue={convertIsoToSlash(data.marriageDate)}
                mask="##/##/####"
                name="marriageDate"
                placeholder="MM/DD/YYYY"
              />
            </InputGroup>
          </InputWrapper>
        </Fade>

        <InputWrapper error={!!errors.taxFileType}>
          <InputLabel htmlFor="taxFileType">How did you file your taxes?</InputLabel>
          <InputError>{safeAccess(errors, 'taxFileType.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputSelect
              name="taxFileType"
              defaultValue={data.taxFileType}
              options={{
                '': '',
                SGL: 'Single',
                HOH: 'Head of Household',
                MFS: 'Filing separately',
                MFJ: 'Filing jointly',
                //NFY: 'Not filed yet',
                //OTHER: 'Other',
              }}
            />
          </InputGroup>
        </InputWrapper>
      </Fade>
      <Fade id="marriedGate" show={data.clientCurrentlyMarried === 'Y'}>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.spouseIncome}>
              <InputLabel htmlFor="spouseIncome">Spouse&#39;s Annual Income</InputLabel>
              <InputError>{safeAccess(errors, 'spouseIncome.detail')}</InputError>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric name="spouseIncome" defaultValue={data.spouseIncome} />
                <InputAddon>.00</InputAddon>
              </InputGroup>
              <InputHelp>
                Please input your spouse's adjusted gross income from their most current Federal
                1040 tax form (Line 8b).
              </InputHelp>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.spousePayPeriods}>
              <InputLabel htmlFor="spousePayPeriods">Spouse Pay Periods per Year</InputLabel>
              <InputError>{safeAccess(errors, 'spousePayPeriods.detail')}</InputError>
              <InputGroup>
                <InputNumeric name="spousePayPeriods" defaultValue={data.spousePayPeriods} />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <InputWrapper error={!!errors.spouseHasStudentLoans}>
          <InputLabel htmlFor="spouseHasStudentLoans">
            Does your spouse have federal student loans?
          </InputLabel>
          <InputError>{safeAccess(errors, 'spouseHasStudentLoans.detail')}</InputError>
          <InputRadio
            name="spouseHasStudentLoans"
            value="Y"
            label="Yes"
            defaultChecked={data.spouseHasStudentLoans === 'Y'}
          />
          <InputRadio
            name="spouseHasStudentLoans"
            value="N"
            label="No"
            defaultChecked={data.spouseHasStudentLoans === 'N'}
          />
          <InputWrapper>
            <InputHelp>
              We are asking for your spouse's loan information because some joint repayment plans
              may result in an overall lower monthly payment for your whole household. We look at
              all options when determining your savings.
            </InputHelp>
          </InputWrapper>
        </InputWrapper>
        <Fade id="spouseHasLoansGate" show={data.spouseHasStudentLoans === 'Y'}>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.spouseLoanBalance}>
                <InputLabel htmlFor="spouseLoanBalance">
                  Spouse&#39;s Loan Balance in Repayment Status
                </InputLabel>
                <InputError>{safeAccess(errors, 'spouseLoanBalance.detail')}</InputError>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    name="spouseLoanBalance"
                    step="0.01"
                    defaultValue={data.spouseLoanBalance}
                  />
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
        </Fade>
        <InputWrapper error={!!errors.taxFileType}>
          <InputLabel htmlFor="taxFileType">How did you file your taxes?</InputLabel>
          <InputError>{safeAccess(errors, 'taxFileType.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputSelect
              name="taxFileType"
              defaultValue={data.taxFileType}
              options={{
                '': '',
                MFS: 'Filing separately',
                MFJ: 'Filing jointly',
                SGL: 'Single',
                HOH: 'Head of Household',
                //NFY: 'Not filed yet',
                //OTHER: 'Other',
              }}
            />
          </InputGroup>
        </InputWrapper>
        <Fade
          id="taxFileTypeGate"
          show={
            data.clientCurrentlyMarried === 'Y' &&
            data.taxFileType !== 'SGL' &&
            data.taxFileType !== 'HOH' &&
            data.taxFileType !== 'MFS' &&
            data.taxFileType !== '' &&
            data.taxFileType != null
          }
        >
          <WorkflowAlert>
            <Alert brand="info">
              There is typically a higher tax cost associated with filing married-separate versus
              married-joint. However the result of filing separately is usually a lower income
              driven repayment, which outweighs the tax cost. This means you see more monthly
              savings and loan forgiveness!
            </Alert>
          </WorkflowAlert>
        </Fade>
      </Fade>
    </Form>
  );
};

TabMarriage.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabMarriage;
